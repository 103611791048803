.header{
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    position: fixed;
    background-color: rgb(10, 25, 47);
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    z-index: 11;
    width: 100%;
    height: 100px;
    box-shadow: none;
    transform: translateY(0px);
    filter: none !important;
    pointer-events: auto !important;
    user-select: auto !important;
}
.Nav{
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    width: 100%;
    color: rgb(204, 214, 246);
    font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
    counter-reset: item 0;
    z-index: 12;
    margin: 0px 40px;
}
.fCfEdx a {
    display: block;
    width: 42px;
    height: 42px;
}
.fCfEdx  a img {
    width: 42px;
    height: 42px;
}
.NavMenue{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
.NavMenue ol{
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding: 0px;
    margin: 0px;
    list-style: none;
}
.NavMenue ol li{
    margin: 0px 10px;
    position: relative;
    font-size: 13px;
    counter-increment: item 1;
    font-family: "Roboto Mono",sans-serif;
}
.NavMenue ol li::before{
    content: "0" counter(item) ".";
    text-align: right;
    color: rgb(100, 255, 218);
    font-size: 12px;
    font-family: "Roboto Mono",sans-serif;
}
.NavMenue ol li span{
    padding: 12px 10px;
}
.NavMenue ol li span:hover{
    color: rgb(100, 255, 218);
    cursor: pointer;
}
.resume{
    color: rgb(100, 255, 218);
    background-color: transparent;
    border: 1px solid rgb(100, 255, 218);
    border-radius: 3px;
    padding: 0.75rem 1rem;
    font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
    line-height: 1;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    margin-left: 10px;
    font-size: 13px;
}
.resume a{
    text-decoration: none;
    color: rgb(100, 255, 218);
}