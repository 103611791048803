.main{
    padding: 200px 150px;
    margin: 0px auto;
    max-width: 1600px;
    min-height: 100vh;
    counter-reset: section 0;
    padding-top: 0px;
    padding-bottom: 0px;
}
.MyName{
    margin: 0px;
    padding: 120px 0px;
    max-width: 1000px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    flex-direction: column;
    align-items: flex-start;
}
.MyName h1{
    color: rgb(100, 255, 218);
    margin: 0px 0px 20px 3px;
    font-size: 16px;
    font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
    font-weight: normal;
}
.MyName h2{
    font-size: 80px;
    line-height: 1.1;
    margin: 0px;
    color: rgb(205, 218, 255);
    font-family: "Concert One";
}
.MyName h3{
    margin: 0;
    font-size: 40px;
    line-height: 1.1;
    color: rgb(136, 146, 176);
}
.paragraph{
    margin-top: 25px;
    width: 100%;
    max-width: 500px;
}
.paragraph p{
    margin: 0px 0px 15px;
    font-size: 17px;
}
.paragraph p a{
    display: inline-block;
    text-decoration: none;
    text-decoration-skip-ink: auto;
    position: relative;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    cursor: pointer;
    color: rgb(100, 255, 218);
}
.paragraph p a::after{
    content: "";
    display: block;
    width: 0%;
    height: 1px;
    position: relative;
    background-color: rgb(100, 255, 218);
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.paragraph p a:hover::after{
    width: 100%;
}
.Getintouch{
    margin-top: 50px;
}
.Getintouch a{
    color: rgb(100, 255, 218);
    background-color: transparent;
    border: 1px solid rgb(100, 255, 218);
    border-radius: 3px;
    padding: 1.25rem 1.75rem;
    font-size: 14px;
    font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
    line-height: 1;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.Aboutme{
    visibility: visible;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s, transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s;
    margin: 0px auto;
    padding: 100px 0px;
    max-width: 1000px;
    position: relative;
}
.Aboutme h3{
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin: 10px 0px 40px;
    width: 100%;
    white-space: nowrap;
    font-size: 30px;
    font-family: "Concert One";
    color: rgb(205, 218, 255);
}
.Aboutme h3::before{
    counter-increment: section 1;
    content: "0" counter(section) ".";
    margin-right: 10px;
    font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
    font-weight: normal;
    color: rgb(100, 255, 218);
    font-size: 30px;
    position: relative;
}
.Aboutme h3::after{
    content: "";
    display: block;
    height: 1px;
    width: 300px;
    background-color: rgb(48, 60, 85);
    position: relative;
    margin-left: 20px;
    margin-top: 5px;
}
.AboutPara{
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: flex-start;
}
.AboutPara1{
    width: 60%;
    max-width: 480px;
}
.AboutPara2{
    position: relative;
    width: 40%;
    max-width: 300px;
    margin-left: 60px;
}
.AboutPara1 ul{
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    overflow: hidden;
    padding: 0px;
    margin: 20px 0px 0px;
    list-style: none;
}
.AboutPara1 div p{
    margin: 0px 0px 15px;
    font-size: 17px;
}
.AboutPara1 div p span{
    color:rgb(100, 255, 218);
}
.AboutPara1 ul li{
    position: relative;
    margin-bottom: 10px;
    padding-left: 20px;
    font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
    font-size: 13px;
    color: rgb(100, 255, 218);
}
.AboutPara1 ul li::before{
    content: "▹";
    position: absolute;
    left: 0px;
    color: rgb(100, 255, 218);
    font-size: 14px;
    line-height: 12px;
}
.AboutPara2 a{
    box-shadow: rgba(2, 12, 27, 0.7) 0px 10px 30px -15px;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    width: 100%;
    position: relative;
    border-radius: 3px;
    background-color: rgb(204, 214, 246);
    margin-left: -20px;
}
.AboutPara2 a::before{
    inset: 0px;
    background-color: rgb(10, 25, 47);
    mix-blend-mode: screen;
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.AboutPara2 a div::after{
    border: 2px solid rgb(100, 255, 218);
    top: 10px;
    left: 10px;
    z-index: -1;
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    transition: all 25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.AboutPara2 a div:hover::after{
    top: 15px;
    left: 15px;
}
.AboutPara2 a div{
    background-color: darkcyan;
    position: relative;
    border-radius: 3px;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.AboutPara2 a div:hover{
    background-image: url("https://github.com/jitenderji1137/nested-loop/assets/113350806/6d0a36f0-a468-45c8-ba4c-61f8c886dcd0");
    background-size: contain;
}
.AboutPara2 a div img{
    width: 100%;
    mix-blend-mode: overlay;
    filter: grayscale(100%) contrast(1) brightness(90%);
}
.AboutPara2 a div img:hover{
    background-image: url("https://github.com/jitenderji1137/nested-loop/assets/113350806/6d0a36f0-a468-45c8-ba4c-61f8c886dcd0");
    background-size: contain;
}
.Experence{
    visibility: visible;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s, transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s;
    outline: none;
    margin: 0px auto;
    padding: 100px 0px;
    position: relative;
    max-width: 700px;
}
.Projects{
    padding: 100px 0px;
}
.Experence h3, .Projects h3{
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin: 10px 0px 40px;
    width: 100%;
    white-space: nowrap;
    font-size: 30px;
    font-family: "Concert One";
}
.Experence h3::before , .Projects h3::before{
    counter-increment: section 1;
    content: "0" counter(section) ".";
    margin-right: 10px;
    font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
    font-weight: normal;
    color: rgb(100, 255, 218);
    font-size: 30px;
    position: relative;
}
.Experence h3::after , .Projects h3::after{
    content: "";
    display: block;
    height: 1px;
    width: 300px;
    background-color: rgb(48, 60, 85);
    position: relative;
    top: 5px;
    margin-left: 20px;
}
.myjourny{
    display: flex;
    align-items: flex-start;
    position: relative;
}
.myjournydiv{
    position: relative;
    width: 100%;
    height: auto;
    padding-top: 12px;
    padding-left: 30px;
}
.myjournydiv h5{
    color: rgb(100, 255, 218);
}
.myjournydiv h4{
    color: rgb(204, 214, 246);
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 5px;
}
.myjournydiv ul li{
    list-style: none;
    margin-bottom: 15px;
}
.myjournydiv ul li span{
    color: rgb(100, 255, 218);
}
.myjournydiv ul{
    padding: 0;
    font-size: 17px;
}
.myjournydiv ul li::before{
    content: "▹";
    position: absolute;
    left: 5px;
    color: rgb(100, 255, 218);
    font-size: 20px;
    line-height: 20px;
}
.Projects div div{
    display: flex;
    width: 100%;
    margin-bottom:100px;
}
.Projects div div a img{
    width: 100%;
    mix-blend-mode: color-dodge;
    filter: grayscale(100%) contrast(1) brightness(90%);
}
.Projects div div a img:hover{
    mix-blend-mode: normal;
    filter: grayscale(0%) contrast(1) brightness(100%);
}
.Projects div div div{
    text-align: right;
    display: block;
}
.float{
    box-shadow: rgba(2, 12, 27, 0.7) 0px 10px 30px -15px;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    position: relative;
    z-index: 2;
    width: 113%!important;
    margin-left: -80px;
    padding: 10px;
    background-color: rgb(23, 42, 69);
    color: rgb(168, 178, 209);
    font-size: 16px;
    border-radius: 3px;
}
.float p{
    font-size: 15px;
}
.float p span,.float-left p span{
    color: rgb(100, 255, 218);
}
.Projects div div div ul{
    position: relative;
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    float: right;
    padding: 0px;
    margin: 25px 0px 10px;
    list-style: none;
}
.Projects div div div ul li{
    font-family: "Roboto Mono", Monaco, monospace;
    font-size: 13px;
    color: rgb(100, 255, 218);
    margin-left: 20px;
    margin-bottom: 7px;
    white-space: nowrap;
}
.Projects div div div h4{
    color: rgb(100, 255, 218);
    font-size: 10px;
    margin-top: 0px;
    font-family: "Roboto Mono",sans-serif;
}
.Projects div div div ul li::after{
    content: "";
    display: block;
    width: 0%;
    height: 1px;
    position: relative;
    background-color: rgb(100, 255, 218);
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.Projects div div div ul li:hover::after {
    width: 100%;

}
.Projects div div div h5 a{
    text-decoration: none;
    color: #d1d1d1;
    font-size: 25px;
}
.Projects div div div h5{
    margin-top: 0px;
}
.Projects div div div div{
    display: flow-root;
    margin-bottom: 0;
}
.Projects div div div div a{
    color: rgb(100, 255, 218);
    font-size: 35px;
}
.Projects2 div{
    text-align: left!important;
}
.float-left{
    box-shadow: rgba(2, 12, 27, 0.7) 0px 10px 30px -15px;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    position: relative;
    z-index: 2;
    width: 113%!important;
    margin-left: 0px;
    padding: 10px;
    background-color: rgb(23, 42, 69);
    color: rgb(168, 178, 209);
    font-size: 16px;
    border-radius: 3px;
}
.Projects2 div ul{
    float: left!important;
}
.Projects2 div ul li{
    margin-left: 0px!important;
    margin-right: 20px;
}
.otherprojects{
    margin: 0px auto;
    padding: 150px 0px;
    max-width: 1000px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    flex-direction: column;
    align-items: flex-start;
}
.viewcode{
    margin-bottom: 50px;
    width: 100%;
}
.otherprojects h4{
    width: 100%;
    font-weight: 600;
    color: rgb(204, 214, 246);
    text-align: center;
    font-size: 30px;
    margin: 5px;
}
.otherprojects a{
    display: inline-block;
    text-decoration: none;
    text-decoration-skip-ink: auto;
    position: relative;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    cursor: pointer;
    color: rgb(100, 255, 218);
    text-align: center;
    font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
    font-size: 14px;
}
.otherprojects div{
    /* width: 100%; */
}
.otherprojects div div{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 10px;
}
.otherprojects div div div{
    -webkit-box-pack: justify;
    display: flex;
    gap: 0;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: flex-start;
    position: relative;
    height: 100%;
    border-radius: 3px;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    background-color: rgb(23, 42, 69);
}
.otherprojects div div div header{
    width: 100%;
}
.fileone{
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 30px;
}
.fileones{
    width: 20%!important;
    justify-content: end!important;
}
.fileones a{
    margin-top: 20px;
    margin-right: 10px;
    font-size: 20px;
    color: white;
}
.fileones a:hover{
    color: rgb(100, 255, 218);
}
.fileicon{
    font-size: 40px;
    margin: 20px;
    color: rgb(100, 255, 218);
}
.nowforh5{
    margin: 20px;
    font-size: 20px;
}
.nowfordiv{
    margin: 20px;
    font-size: 13px;
}
.forultag{
    display: flex;
    align-items: flex-end;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-wrap: wrap;
    padding: 0px;
    margin: 20px;
    list-style: none;
}
.forultag li{
    font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
    font-size: 12px;
    color: rgb(100, 255, 218);
    line-height: 1.75;
    margin-right: 15px;
}
.forultag li::marker{
    display: none;
}
.forultag li::after{
    content: "";
    display: block;
    width: 0%;
    height: 1px;
    position: relative;
    background-color: rgb(100, 255, 218);
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.forultag li:hover::after{
    width: 100%;
}
.moreprojects{
    color: rgb(100, 255, 218);
    background-color: transparent;
    border: 1px solid rgb(100, 255, 218);
    border-radius: 3px;
    font-size: 13px;
    font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
    line-height: 1;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    padding: 1.25rem 1.75rem;
    margin: 100px auto 0px;
}
.contactinfo{
    padding: 150px 0px;
    text-align: center;
    max-width: 600px;
    margin: 0px auto 0px;
}
.contactinfo h3{
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    margin: 0px 0px 0px;
    width: 100%;
    white-space: nowrap;
    display: block;
    color: rgb(100, 255, 218);
    font-size: 16px;
    font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
    font-weight: normal;
    -webkit-box-pack: center;
    justify-content: center;

}
.contactinfo h3::before{
    counter-increment: section 1;
    content: "0" counter(section) ".";
    margin-right: 10px;
    font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
    font-weight: normal;
    color: rgb(100, 255, 218);
    font-size: 20px;
    position: relative;
}
.contactinfo h4{
    font-size: 56px;
    margin: 0;
    font-family: "Concert One";
}
.contactinf h4{
    font-size: 56px;
    margin: 0;
    color: rgb(100, 255, 218);
    font-family: "Concert One";
    text-align: center;
}
.contactinfo div{
    font-size: 18px;
}
.contactinfo a{
    display: inline-block;
    text-decoration: none;
    text-decoration-skip-ink: auto;
    position: relative;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    cursor: pointer;
    color: rgb(100, 255, 218);
    background-color: transparent;
    border: 1px solid rgb(100, 255, 218);
    border-radius: 3px;
    padding: 1.25rem 1.75rem;
    font-size: 14px;
    font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
    line-height: 1;
    margin-top: 50px;
}
footer a{
    text-decoration: none;
    display: flex;
    color: rgb(100, 255, 218);
    justify-content: center;
    font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
}
.myrealtimeproject{
    border: 2px solid rgb(100, 255, 218);
    padding: 20px;
    height: max-content;
    border-radius: 10px;
    margin: 20px;
    margin-bottom: 100px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
#realtext{
    color: rgb(100, 255, 218);
    font-family: "Concert One";
    margin: 25px;
    font-size: 25px;
    cursor: pointer;
}
#realtext::after{
    content: "";
    display: block;
    height: 1px;
    width: 300px;
    background-color: rgb(100, 255, 218);
    position: relative;
    top: 15px;
    margin-left: 20px;
}
#realtimepara{
    font-size: 18px;
    margin: auto;
    font-family: "Concert One";
    width: 80%;
}
#realabout{
    color: rgb(100, 255, 218);
    margin: 40px;
    width: auto;
    cursor: pointer;
    font-family: "Concert One";
    display: flex;
    justify-content: center;
}
#realul{
    display: flex;
    justify-content: center;
}
#realul li{
    margin: 20px;
    color: rgb(100, 255, 218);
    font-family: "Concert One";
    cursor: pointer;
}